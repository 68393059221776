@import './../../../../scss/theme-bootstrap';

.product-benefits {
  padding: 15px 0 0;
  display: table;
  height: 100%;
  width: 100%;
  &__list {
    max-width: 100%;
    display: flex;
    justify-content: center;
    flex-wrap: wrap;
  }
  &__item {
    position: relative;
    width: 25%;
    float: $ldirection;
    margin-bottom: 15px;
    padding-bottom: 35px;
    &-icon {
      display: block;
      margin: auto;
    }
    &-label {
      font-size: 11px;
      line-height: 1.1;
      text-align: center;
      position: absolute;
      bottom: 0;
      display: block;
      width: 100%;
    }
    .mantle-media-asset {
      display: inline;
    }
  }
}
